<script setup lang="ts">
import { AuthFormStep, AuthFormType } from '~/enums'
import type { PropType } from 'vue'

const props = defineProps({
	title: {
		type: String,
		default: undefined
	},
	description: {
		type: String,
		default: undefined
	},
	type: {
		type: Number as PropType<AuthFormType>,
		default: AuthFormType.Phone
	},
	callback: {
		type: Function,
		default: () => ({})
	}
})

const { t } = useI18n({
	useScope: 'local'
})

const state = reactive({
	login: '',
	code: '',
	step: AuthFormStep.Login,
	digits: 4,
	expiredAt: new Date(new Date().getTime()),
})

const localTitle = computed(() => {
	return state.step === AuthFormStep.Code ? t('title.code') : props.title ?? t('title.login')
})
</script>

<template>
	<div class="flex flex-col py-9 px-14">
		<div
			class="mb-4 text-center text-gray-600 text-xl font-semibold"
		>
			{{ localTitle }}
		</div>
		<div
			v-if="description"
			class="text-sm mb-5"
		>
			{{ description }}
		</div>
		<AuthFormLogin
			v-if="state.step === AuthFormStep.Login"
			v-model="state"
			:type="type"
		/>
		<AuthFormCode
			v-if="state.step === AuthFormStep.Code"
			v-model="state"
			:type="type"
			:callback="callback"
		/>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"title": {
			"login": "Авторизация",
			"code": "Введите код"
		}
	}
}
</i18n>