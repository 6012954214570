<script setup lang="ts">
import * as yup from 'yup'
import '~/libs/yup/yup-phone'
import type { FormErrorEvent } from '#ui/types'
import { ServerStatusCode } from '~/enums/server'
import { AuthFormStep, AuthFormType } from '~/enums'
import type { PropType } from 'vue'

const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	type: {
		type: Number as PropType<AuthFormType>,
	},
})

const emits = defineEmits([
	'update:modelValue'
])

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const authStore = useAuthStore()

const form = ref()
const isLoading = ref(false)

let schema = yup.object().shape({})
if (process.client) {
	schema = yup.object().shape({
		login: props.type === AuthFormType.Email
			? yup.string()
				.email(t('message.email.format'))
				.required(t('message.email.empty'))
			: yup.string()
				.phone('RU', false, t('message.phone.format'))
				.required(t('message.phone.empty')),
	})
}

const isValid = computed(() => schema.isValidSync(localValue.value))

const onSubmit = async () => {
	isLoading.value = true
	
	const result = await authStore.auth({
		login: localValue.value.login
	})
	
	isLoading.value = false
	
	const error = result.error?.value
	if (error) {
		onSubmitError(error)
		return
	}
	
	localValue.value.digits = result.data.value?.digits ?? 4
	localValue.value.expiredAt = new Date(new Date().getTime() + (result.data.value?.seconds ?? 60) * 1000)
	localValue.value.step = AuthFormStep.Code
}

const onSubmitError = (error: any) => {
	const errors: any[] = []
	switch (error.statusCode) {
		case ServerStatusCode.UnprocessableContent:
			Object.entries(error.data.errors).forEach(([key, value]) => {
				errors.push({
					path: key,
					message: (value as Array<string>).join('')
				})
			})
			break
		case ServerStatusCode.ToEarly:
			errors.push({
				path: 'login',
				message: error.data.message
			})
			break
		case ServerStatusCode.ToManyRequests:
			errors.push({
				path: 'login',
				message: tg('error.many_requests', {
					seconds: tg('unit.sec_plural', parseInt((error.cause as any).response.headers.get('Retry-After')))
				})
			})
			break
		default:
			break
	}
	if (errors.length) {
		form.value.setErrors(errors)
	}
}

const onError = (event: FormErrorEvent) => {
	const element = document.getElementById(event.errors[0].id)
	element?.focus()
	element?.scrollIntoView({
		behavior: 'smooth',
		block: 'center'
	})
}
</script>

<template>
	<ClientOnly>
		<UForm
			ref="form"
			:schema="schema"
			:state="localValue"
			:validate-on="['submit', 'change', 'input']"
			@submit="onSubmit"
			@error="onError"
		>
			<UFormGroup
				name="login"
				:label="type === AuthFormType.Email ? t('field.email') : t('field.phone')"
				:ui="{
					label: {
						wrapper: 'flex items-center justify-center',
						base: 'block text-base font-normal text-gray-600'
					},
					container: 'mt-3 relative',
				}"
				required
			>
				<UInput
					v-if="type === AuthFormType.Email"
					v-model="localValue.login"
					type="email"
					color="primary"
					size="xl"
					class="bg-white"
					:placeholder="t('placeholder.email')"
					:ui="{
						padding: {
							xl: '!px-2.5 py-3.5'
						},
					}"
					autofocus
				/>
				<UiPhoneInput
					v-model="localValue.login"
					size="xl"
					:autofocus="true"
					:ui="{
						padding: {
							xl: 'py-3.5'
						},
						leading: {
              padding: {
								xl: 'ps-10'
              }
						}
					}"
				/>
			</UFormGroup>
			<UButton
				type="submit"
				size="xl"
				class="mt-4"
				:label="t('button.submit')"
				:ui="{
					font: 'font-semibold',
					padding: {
						xl: 'px-3.5 py-3.5'
					}
				}"
				:disabled="isLoading || !isValid"
				block
			>
				<template #leading>
					<UIcon
						v-if="isLoading"
						name="i-svg-spinners-ring-resize"
						class="w-5 h-5"
						dynamic
					/>
				</template>
			</UButton>
		</UForm>
	</ClientOnly>
</template>

<i18n lang="json">
{
	"ru": {
		"field": {
			"phone": "Введите ваш телефон",
			"email": "Введите ваш E-mail"
		},
		"placeholder": {
			"phone": "+7 916 123-45-67",
			"email": "name{'@'}email.com"
		},
		"button": {
			"submit": "Получить код"
		},
		"message": {
			"email": {
				"empty": "Вы не ввели E-mail",
				"format": "Вы ввели неверный формат E-mail"
			},
			"phone": {
				"empty": "Вы не ввели телефон",
				"format": "Вы ввели неверный формат телефона"
			},
			"send_again_after": "Запросить код повторно через {seconds}"
		}
	}
}
</i18n>